import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.png";
import $ from "jquery";
import { handleClickScroll } from "../../lib/helpers";
import Swal from 'sweetalert2'


const Errore = () =>{
  Swal.fire(
    'Donazioni attualmente disabilitate',
    'Le Donazioni non sono ancora state abilitate.',
    'error'
  )
}

const HeaderDone = () => {
  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // mobile menu
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      let mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);

  // active link switching
  const { hash, pathname } = useLocation();
  const isActiveLink = (id) => {
    return id == hash ? "active" : "";
  };

  return (
    <header id="home">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />

      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className={cn("container custom-container")}>
          <div className="row">
            <div className="col-12">
              <div className={"mobile-nav-toggler"}>
                <i className="fas fa-bars" />
              </div>

              <div className="menu-wrap">
                <nav className={"menu-nav"}>
                  <div className="logo">
                    <Link to={"/"}>
                      <img src={logoImage} alt="Western Legends Logo" />
                    </Link>
                  </div>

                  <div className={cn("navbar-wrap main-menu d-none d-lg-flex")}>
                    <ul className={"navigation"}>
                      <li
                        className={cn(
                          hash == "" && "",
                          ""
                        )}
                      >
                        <Link
                          to="/"
                          className={"section-link"}
                          onClick={() => handleClickScroll("home")}
                        >
                          Home
                        </Link>
                      </li>
                      <li
                        className={cn(
                          hash == "" && "",
                          ""
                        )}
                      >
                        <Link to="/donazioni">Donazioni</Link>
                      </li>
                      <li className={isActiveLink("#faq")}>
                        <Link
                          to="/#faq"
                          className={"section-link"}
                          onClick={() => handleClickScroll("faq")}
                        >
                          FAQ
                        </Link>
                      </li>
                      <li className={isActiveLink("#staff")}>
                        <Link
                          to="/#staff"
                          className={"section-link"}
                          onClick={() => handleClickScroll("staff")}
                        >
                          Lo Staff
                        </Link>
                      </li>
                      <li className={isActiveLink("#contact")}>
                        <Link
                          to="/#contact"
                          className={"section-link"}
                          onClick={() => handleClickScroll("contact")}
                        >
                          Contatti
                        </Link>
                      </li>
                      <li className={"menu-item-has-children"}>
                        <Link to="">Link Utili ⮟</Link>
                        <ul className={cn("sub-menu")}>
                        <li className={cn(pathname == "/ip" && "active")}>
                            <Link to="/ip">IP Server</Link>
                          </li>
                          <li className={cn(pathname == "/guida/saltychat" && "active")}>
                            <Link to="/guida/saltychat">Guida SaltyChat</Link>
                          </li>
                          <li className={cn(pathname == "/mappa" && "active")}>
                            <Link to="/mappa">Mappa di Gioco</Link>
                          </li>
                          {/*<li
                            className={cn(
                              pathname == "" && "active"
                            )}
                          >
                            <Link to="https://docs.google.com/document/d/1UvKhZFYx2hT_cOvEohqpIjYqIH-s1KppvwjUIwDGlqk/edit?usp=sharing">Storia e Linee Guida</Link>
                          </li>
                          <li
                            className={cn(
                              pathname == "" && "active"
                            )}
                          >
                            <Link to="https://docs.google.com/document/d/1k1R6CG2x3wxu5kYX4mS_iiBAp0gd5ajSSOikonuAL1k/edit?usp=sharing">Regolamento</Link>
                          </li>
                          <li
                            className={cn(
                              pathname == "" && "active"
                            )}
                          >
                            <Link to="https://docs.google.com/spreadsheets/d/14FOtthl40qsYcGLAhdNbsLnGZSoPAXC8K645EFu3e_A">Sistema Giudiziario</Link>
                          </li>*/}
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className={cn("header-action", "d-none d-md-block")}>
                    <ul>
                      <li className={"header-btn"}>
                      <Link to="https://discord.gg/westernlegends" className="btn">
                          Entra Ora
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className={"mobile-menu"}>
                <nav className={"menu-box"}>
                  <div className={"close-btn"}>
                    <i className="fas fa-times"></i>
                  </div>
                  <div className={"nav-logo"}>
                    <Link to="/">
                      <img src={logoImage} alt="Western Legends Logo" title="" />
                    </Link>
                  </div>

                  <div className={"menu-outer"}>
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->  */}
                  </div>

                  <div className={"social-links"}>
                  <ul className="clearfix">
                      <li>
                        <a href="https://www.instagram.com/westernlegendsrp/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://discord.gg/westernlegends">
                          <i className="fab fa-discord"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@westernlegendss">
                          <i className="fab fa-tiktok"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className={"menu-backdrop"} />
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderDone;
